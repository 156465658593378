import { ENDPOINTS, IGenerateVocabularyPractice, IItems, Requests, getApiUrl } from "@/common";
import { IGetDictionary, IGetVocaQuestionPayload } from "../queries";

export const fetchVocaQuestion = async ({ questionId, band }: IGetVocaQuestionPayload["query"]): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_VOCA_QUESTION, { questionId }, true) + "?band=" + band;
  const data: any = await Requests.get(apiUrl);
  return data;
};

export const fetchDictionary = async ({ keyword }: IGetDictionary["query"]): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.GET_WORD_SEARCH, { keyword }, false);
  const data: any = await Requests.get(apiUrl);
  return data;
};

export const generateVocabularyPractice = async (payload: IGenerateVocabularyPractice): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.GENERATE_VOCABULARY_PRACTICE);
  const data: any = await Requests.post(apiUrl, payload);
  return data;
};