import { useAnalyzeGrammarSentence, useGenerateGrammarPractice } from "@/services-react-query/queries";
import { ExerciseGrammarData, IApplicationState } from "@/store-redux";
import CloseIcon from "@mui/icons-material/Close";
import SportsMartialArtsIcon from "@mui/icons-material/SportsMartialArts";
import {
  Box,
  Button,
  CircularProgress,
  colors,
  Dialog,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import GrammarPracticeModal from "./GrammarPracticeModal";

interface IAnalyzeGrammar {
  grammar_analysis: {
    grammar_point: string;
    position: string;
    explanation: string;
  }[];
}

function GrammarPractice() {
  const [openModal, setOpenModal] = useState(false);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const [listText, setListText] = useState<string[]>([]);

  const [openPracticeModal, setOpenPracticeModal] = useState(false);
  const [analyzeGrammarData, setAnalyzeGrammarData] = useState<IAnalyzeGrammar | undefined>(undefined);
  const [exerciseData, setExerciseData] = useState<ExerciseGrammarData['grammar_exercises'] | undefined>(undefined);


  const { mutate: analyzeGrammar, isLoading: analyzeGrammarLoading } = useAnalyzeGrammarSentence({
    onSuccess(data) {
      setAnalyzeGrammarData(data?.data?.result);
      setOpenModal(true);
    },
  });

  const { mutate: generateGrammar, isLoading: generateGrammarLoading } = useGenerateGrammarPractice({
    onSuccess(data) {
      setOpenModal(false);
      setExerciseData(data?.data?.result);
      setTimeout(() => {
        setOpenPracticeModal(true);
      }, 100);
    },
  });

  const onClose = () => {
    setListText([]);
    setOpenModal(false);
  };

  const onGenerate = () => {
    generateGrammar({
      sentence: editCustomAnswerContent.data,
      grammar: listText,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          analyzeGrammar({
            sentence: editCustomAnswerContent.data,
          });
          // setOpenPracticeModal(true);
        }}
        disabled={analyzeGrammarLoading}
      >
        {analyzeGrammarLoading ? (
          <CircularProgress
            size={24}
            sx={{
              marginRight: "8px",
            }}
          />
        ) : (
          <SportsMartialArtsIcon />
        )}
        Grammar Practice
      </Button>
      <Dialog open={openModal} onClose={onClose}>
        <Box
          sx={{
            padding: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              marginBottom: "32px",
              color: colors.blue[500],
              textAlign: "center",
              " span": {
                fontSize: "18px",
              },
            }}
          >
            <Typography component={"span"}>Please choose type of grammar you want to practice </Typography>
          </Box>
          <Box
            sx={{
              border: "1px solid #e1e1e1",
              padding: "8px",
            }}
            className="sentence_vocabulary"
          >
            {editCustomAnswerContent.data.toString()}
          </Box>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      fontWeight: "bold",
                      padding: "8px",
                    },
                  }}
                >
                  <TableCell>Grammar Point</TableCell>
                  <TableCell>Position</TableCell>
                  <TableCell>Explanation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analyzeGrammarData?.grammar_analysis?.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      cursor: "pointer",
                      td: {
                        padding: "8px",
                      },
                      backgroundColor: listText.includes(item.grammar_point) ? colors.blue[50] : "white",
                    }}
                    onClick={() => {
                      setListText((prev) => {
                        const existed = prev.find((val) => val === item.grammar_point);
                        if (existed) {
                          return prev.filter((val) => val !== item.grammar_point);
                        }
                        return [...prev, item.grammar_point];
                      });
                    }}
                  >
                    <TableCell>{item?.grammar_point ?? ""}</TableCell>
                    <TableCell>{item?.position ?? ""}</TableCell>
                    <TableCell>{item?.explanation ?? ""}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
              "& button": {
                textTransform: "none",
              },
            }}
            onClick={onGenerate}
          >
            <Button variant="contained" disabled={generateGrammarLoading}>
              {generateGrammarLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    marginRight: "8px",
                  }}
                />
              )}
              Generate practice
            </Button>
          </Box>
        </Box>
      </Dialog>
      <GrammarPracticeModal
        exerciseData={exerciseData}
        openModal={openPracticeModal}
        setOpenModal={setOpenPracticeModal}
      />
    </>
  );
}

export default GrammarPractice;
