import React, { useState } from "react";
import { IUserAnswersGrammar, VerbFormsExerciseData } from "@/common";
import { Box, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

interface Props {
  data: VerbFormsExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswersGrammar>>;
}

const VerbFormsExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [answers, setAnswers] = useState<Record<number, string>>({});

  const handleChange = (sentenceIndex: number, blankIndex: number, value: string): void => {
    const newAnswers = {
      ...answers,
      [sentenceIndex]: {
        ...(answers[sentenceIndex] || {}),
        [blankIndex]: value,
      },
    };
    setAnswers(newAnswers);
    setUserAnswers((prev) => ({
      ...prev,
      verbForms: {
        ...prev.verbForms,
        ...newAnswers,
      },
    }));
  };

  const renderSentenceWithBlanks = (sentence: string, correctAnswers: string[], sentenceIndex: number) => {
    const parts = sentence.split(/\([^)]+\)/);
    const blanks = sentence.match(/\([^)]+\)/g)?.map((blank) => blank.slice(1, -1)) || [];

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {part}
        {index < blanks.length && (
          <Box
            component="span"
            sx={
              isSubmitted && !(answers[sentenceIndex]?.[index]?.toLowerCase() === correctAnswers[index]?.toLowerCase())
                ? {
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }
                : {}
            }
          >
            <Box component="span" sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <input
                type="text"
                value={answers[sentenceIndex]?.[index] || ""}
                onChange={(e) => handleChange(sentenceIndex, index, e.target.value)}
                style={{
                  backgroundColor: isSubmitted
                    ? answers[sentenceIndex]?.[index]?.toLowerCase() === correctAnswers[index]?.toLowerCase()
                      ? green[500]
                      : red[500]
                    : "white",
                }}
                disabled={isSubmitted}
              />
              <Typography component="span" sx={{ color: "text.secondary" }}>
                ({blanks[index]})
              </Typography>
            </Box>
            {isSubmitted &&
              !(answers[sentenceIndex]?.[index]?.toLowerCase() === correctAnswers[index]?.toLowerCase()) && (
                <Typography component="span" color={green[500]}>
                  =&gt; {correctAnswers[index]}
                </Typography>
              )}
          </Box>
        )}
      </React.Fragment>
    ));
  };

  return (
    <div className="exercise-section fill-blanks">
      <h2>Verb Forms Exercise</h2>
      <p>{data.instructions}</p>

      <div className="sentences">
        {data.sentences.map((sentence, index) => (
          <div key={index} className="sentence">
            {`${index + 1}) `}
            {renderSentenceWithBlanks(sentence.sentence, sentence.answer.split(", "), index)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VerbFormsExercise;
