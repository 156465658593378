import { ExerciseGrammarData, ExerciseGramnmarResults, IUserAnswersGrammar } from "@/common";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, IconButton } from "@mui/material";
import React, { useState } from "react";
import ResultsSummary from "./components/ResultsSummary";
import SentenceFormationExercise from "./components/SentenceFormationExercise";
import VerbFormsExercise from "./components/VerbFormsExercise";
import "./styles.scss";
import AddVocabulary from "@/components/AddVocabulary";
import TranslateEnglish from "./components/TranslateEnglish";

// const exerciseData = {
//   verb_forms: {
//     instructions: "Conjugate the verbs in parentheses into the correct tense based on the given context.",
//     sentences: [
//       {
//         sentence: "Encountering accommodations with inadequate security features (heighten) my safety concerns.",
//         answer: "heightens",
//       },
//       {
//         sentence: "Without proper surveillance, I (feel) increasingly vulnerable.",
//         answer: "feel",
//       },
//       {
//         sentence: "Prior to discovering broken locks, I (assume) the property was secure.",
//         answer: "had assumed",
//       },
//       {
//         sentence: "Such features (push) me towards properties with robust security measures.",
//         answer: "have pushed",
//       },
//     ],
//   },
//   sentence_formation: {
//     instructions: "Rearrange the given words or phrases to form grammatically correct sentences.",
//     sentences: [
//       {
//         words: ["heightened", "my", "concerns", "inadequate", "security", "accommodations"],
//         answer: "Inadequate security accommodations have heightened my concerns.",
//       },
//       {
//         words: ["about", "properties", "robust", "with", "security", "measures", "pushing"],
//         answer: "Pushing me towards properties with robust security measures.",
//       },
//       {
//         words: ["locks", "broken", "security", "which", "are", "a", "concern"],
//         answer: "Broken locks, which are a security concern.",
//       },
//       {
//         words: ["my", "feelings", "regarding", "inadequate", "security", "features"],
//         answer: "My feelings regarding inadequate security features.",
//       },
//     ],
//   },
//   translation: {
//     instructions: "Translate the given sentences between English and Vietnamese.",
//     exercises: [
//       {
//         english: "Encountering accommodations with inadequate security features has heightened my safety concerns.",
//         vietnamese:
//           "Việc gặp phải chỗ ở có tính năng an ninh không đầy đủ đã làm gia tăng mối lo ngại về an toàn của tôi.",
//       },
//       {
//         english: "I am concerned about properties that lack surveillance.",
//         vietnamese: "Tôi lo lắng về những bất động sản thiếu sự giám sát.",
//       },
//       {
//         english: "Robust security measures are essential for my peace of mind.",
//         vietnamese: "Các biện pháp an ninh vững mạnh là cần thiết cho sự an tâm của tôi.",
//       },
//       {
//         english: "I prefer living in places that offer adequate security.",
//         vietnamese: "Tôi thích sống ở những nơi có bảo đảm an ninh đầy đủ.",
//       },
//     ],
//   },
// };

interface IGrammarPracticeModalProps {
  exerciseData?: ExerciseGrammarData["grammar_exercises"];
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function GrammarPracticeModal({ exerciseData, openModal, setOpenModal }: IGrammarPracticeModalProps) {
  const [results, setResults] = useState<ExerciseGramnmarResults>({
    totalScore: 0,
    totalQuestions: 0,
    verbForms: { score: 0, total: 0, answers: {} },
    sentenceFormation: { score: 0, total: 0, answers: {} },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userAnswers, setUserAnswers] = useState<IUserAnswersGrammar>({
    verbForms: {},
    sentenceFormation: {},
  });

  if (!exerciseData) return <></>;

  const checkVerbFormsAnswers = () => {
    const score = exerciseData.verb_forms.sentences.reduce((acc, sentence, index) => {
      const userAnswersTemp = (userAnswers.verbForms as Record<number, string[]>)?.[index] || [];
      const correctAnswers = sentence.answer.split(", ");

      // Check if all answers match (case-insensitive)
      const isCorrect =
        userAnswersTemp.length === correctAnswers.length &&
        userAnswersTemp.every((answer, i) => answer.toLowerCase() === correctAnswers[i].toLowerCase());

      return isCorrect ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.verb_forms.sentences.length,
      answers: userAnswers.verbForms || {},
    };
  };

  const checkSentenceFormationAnswers = () => {
    const score = exerciseData.sentence_formation.sentences.reduce((acc, sentence, index) => {
      const userAnswer = (userAnswers.sentenceFormation as Record<number, string[]>)?.[index]?.join(" ");
      return userAnswer?.toLocaleLowerCase() === sentence.answer?.toLocaleLowerCase() ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.sentence_formation.sentences.length,
      answers: userAnswers.sentenceFormation || {},
    };
  };

  const calculateResults = () => {
    const newResults = {
      verbForms: checkVerbFormsAnswers(),
      sentenceFormation: checkSentenceFormationAnswers(),
    };

    const totalScore = Object.values(newResults).reduce((acc, curr) => acc + curr.score, 0);
    const totalQuestions = Object.values(newResults).reduce((acc, curr) => acc + curr.total, 0);

    setResults({
      ...newResults,
      totalScore,
      totalQuestions,
    });
    setIsSubmitted(true);
  };

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen>
      <Box
        sx={{
          padding: "32px",
        }}
        className="free-learn-to-idea__container"
      >
        <Box
          sx={{
            position: "fixed",
            right: "30px",
            top: "20px",
          }}
        >
          <IconButton
            size="small"
            sx={{
              backgroundColor: "gray",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>
        <div className="app-container">
          <h1>Grammar Exercises</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              calculateResults();
            }}
          >
            <div className="exercises-wrapper">
              <VerbFormsExercise
                data={exerciseData.verb_forms}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <SentenceFormationExercise
                data={exerciseData.sentence_formation}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <TranslateEnglish data={exerciseData.translation} isSubmitted={isSubmitted} />

              {!isSubmitted ? (
                <Button type="submit" variant="contained" className="submit-all-btn">
                  Submit all answers
                </Button>
              ) : (
                <ResultsSummary
                  results={results}
                  onTryAgain={() => {
                    setIsSubmitted(false);
                    setUserAnswers({
                      verbForms: {},
                      sentenceFormation: {},
                    });
                  }}
                  onContinueAnswer={() => {
                    setIsSubmitted(false);
                    setOpenModal(false);
                  }}
                />
              )}
            </div>
          </form>
        </div>
      </Box>
      <AddVocabulary />
    </Dialog>
  );
}

export default GrammarPracticeModal;
