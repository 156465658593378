import { ExerciseGrammarData } from "@/common";
import { Box } from "@mui/material";
import { useState } from "react";

interface Props {
  data: ExerciseGrammarData["grammar_exercises"]["translation"];
  isSubmitted?: boolean;
}

function TranslateEnglish({ data, isSubmitted }: Props) {
  const [userAnswers, setUserAnswers] = useState<string[]>(new Array(data.exercises.length).fill("")); // Khởi tạo state cho câu trả lời

  const handleChange = (index: number, value: string) => {
    const updatedAnswers = [...userAnswers];
    updatedAnswers[index] = value;
    setUserAnswers(updatedAnswers);
  };

  return (
    <div className="exercise-section fill-blanks">
      <h2>Translate idea to English</h2>
      <p>{data.instructions}</p>

      <Box className="sentences">
        {data.exercises.map((sentence, index) => {
          return (
            <Box key={index} mb={2}>
              <Box
                sx={{
                  display: "flex",
                  mb: 1,
                }}
              >
                {`${index + 1}) `}
                <Box
                  sx={{
                    marginLeft: "4px",
                  }}
                >
                  {sentence.vietnamese}
                </Box>
              </Box>
              <input
                type="text"
                value={userAnswers[index]}
                onChange={(e) => handleChange(index, e.target.value)}
                disabled={isSubmitted}
              />
              {isSubmitted ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "4px",
                    fontStyle: "italic",
                  }}
                >
                  {sentence.english}
                </Box>
              ) : null}
            </Box>
          );
        })}
      </Box>
    </div>
  );
}

export default TranslateEnglish;
