import LayoutTab from "@/components/LayoutTab";
import { ROUTES } from "@/constants";
import { extractContent, sortItemOrder } from "@/helpers";
import {
  ETypeItem,
  getDetailItem,
  getListAspects,
  getListCategories,
  IApplicationState,
  parseParamUrl,
} from "@/store-redux";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import ListSuggestionAspect from "./ListSuggestionAspect";
import MyIdeaPath from "./MyIdeaPath";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import AnswerAspectToIdea from "./AnswerAspectToIdea";
import AssistantIcon from "@mui/icons-material/Assistant";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
enum EAspectView {
  answer_by_aspect = "answer_by_aspect",
  sugesstion_by_aspect = "sugesstion_by_aspect",
}
const LearnQuestionPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { questionId } = useParams();

  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const listCategory = useSelector((state: IApplicationState) => state.listCategories);
  const listAspectPage = useSelector((state: IApplicationState) => state.listAspects);
  const [categorySelectedId, setCategorySelectedId] = useState<string | null>(null);
  const [aspectSelectedId, setAspectSelectedId] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const typeQuestion = searchParams.get("type-question");
  const [aspectViewType, setAspectViewType] = useState<EAspectView>(EAspectView.answer_by_aspect);

  useEffect(() => {
    if (questionId && detailQuestion.data?._id !== questionId) {
      dispatch(getDetailItem(questionId));
    }
    if (!listCategory.data?.length) {
      dispatch(
        getListCategories({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          type: ETypeItem.category,
          isOnlyPublic: true,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (categorySelectedId) {
      dispatch(
        getListAspects({
          skip: 0,
          limit: Number.MAX_SAFE_INTEGER,
          sortBy: "createdAt",
          parentId: categorySelectedId,
          questionId: questionId,
          type: ETypeItem.aspect,
        })
      );
    }
  }, [categorySelectedId]);
  const listSortedCategories = useMemo(() => {
    return sortItemOrder(listCategory.data);
  }, [listCategory]);
  const categorySelected = listCategory.data?.find((item) => item._id === categorySelectedId) ?? null;
  return (
    <Box
      sx={{
        display: "flex",
        "& h2.layout_title": {
          fontSize: "24px",
        },
      }}
    >
      <PanelGroup direction="horizontal">
        <Panel defaultSize={50}>
          <Box
            sx={{
              flex: 1,
            }}
          >
            <LayoutTab
              listTab={listSortedCategories.map((category) => {
                return {
                  id: category._id,
                  label: extractContent(category?.name ?? ""),
                };
              })}
              breadcrumbs={[
                {
                  text: "Learn",
                  link: ROUTES.LEARN,
                },
                {
                  text: `Topic & Question`,
                  link: parseParamUrl(ROUTES.LEARN_TOPIC_QUESTION, undefined, {
                    "type-question": typeQuestion,
                  }),
                },
                {
                  text: `Ideas`,
                },
              ]}
              title={extractContent(detailQuestion.data?.name ?? "")}
              subTitle="Get ideas to learn"
              tabValue={categorySelectedId ?? false}
              setTabValue={(id) => setCategorySelectedId(id as string | null)}
            >
              <Box
                sx={{
                  scrollbarWidth: "none" /* Firefox */,
                  msOverflowStyle: "none" /* IE and Edge */,
                  "& ::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {categorySelected && (
                  <Box
                    sx={{
                      display: "flex",
                      padding: "8px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        fontSize: "12px",
                        color: "#6A6969",
                        cursor: "pointer",
                        ...(aspectViewType === EAspectView.answer_by_aspect
                          ? {
                              color: "#2A9D8F",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setAspectViewType(EAspectView.answer_by_aspect);
                      }}
                    >
                      <QuestionAnswerIcon
                        fontSize="small"
                        sx={{
                          marginRight: "8px",
                        }}
                      />
                      Answer By Aspect
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        padding: "4px 8px",
                        fontSize: "12px",
                        color: "#6A6969",
                        cursor: "pointer",
                        ...(aspectViewType === EAspectView.sugesstion_by_aspect
                          ? {
                              color: "#2A9D8F",
                            }
                          : {}),
                      }}
                      onClick={() => {
                        setAspectViewType(EAspectView.sugesstion_by_aspect);
                      }}
                    >
                      <AssistantIcon
                        fontSize="small"
                        sx={{
                          marginRight: "8px",
                        }}
                      />
                      Suggestion By Aspect
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    height: "calc(100vh - 240px)",
                    overflowY: "auto",
                  }}
                >
                  {categorySelected && aspectViewType === EAspectView.answer_by_aspect && (
                    <AnswerAspectToIdea
                      aspects={sortItemOrder(listAspectPage.data)}
                      question={extractContent(detailQuestion.data?.name ?? "")}
                    />
                  )}
                  {categorySelected && aspectViewType === EAspectView.sugesstion_by_aspect && (
                    <>
                      {categorySelected && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            margin: "10px 0",
                          }}
                        >
                          Choose aspects & get ideas of "{extractContent(categorySelected?.name ?? "")}"
                        </Typography>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          marginLeft: "-5px",
                        }}
                      >
                        {sortItemOrder(listAspectPage.data)?.map((aspect, idx: number) => {
                          return (
                            <Box
                              key={idx}
                              sx={{
                                border: "1px solid #C6C1C1",
                                color: "#313131",
                                display: "flex",
                                padding: "10px 20px",
                                margin: "5px",
                                borderRadius: "5px",
                                cursor: "pointer",
                                "&:hover": {
                                  border: `1px solid ${theme.palette.primary.main}`,
                                  color: theme.palette.primary.main,
                                  "& .title": {
                                    fontWeight: "bold",
                                  },
                                  "& .nb_items": {
                                    color: theme.palette.primary.main,
                                    border: `1px solid ${theme.palette.primary.main}`,
                                  },
                                },
                                ...(aspectSelectedId === aspect._id
                                  ? {
                                      border: `1px solid ${theme.palette.primary.main}`,
                                      color: theme.palette.primary.main,
                                      "& .title": {
                                        fontWeight: "bold",
                                      },
                                      "& .nb_items": {
                                        color: theme.palette.primary.main,
                                        border: `1px solid ${theme.palette.primary.main}`,
                                      },
                                    }
                                  : {}),
                              }}
                              onClick={() => {
                                setAspectSelectedId(aspect._id);
                              }}
                            >
                              <Box className="title" dangerouslySetInnerHTML={{ __html: aspect.name || "" }} />
                              <Box
                                className="nb_items"
                                sx={{
                                  color: "#6A6969",
                                  border: "1px solid #6A6969",
                                  borderRadius: "50%",
                                  padding: "0 5px",
                                  minHeight: "24px",
                                  minWidth: "24px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  fontSize: "12px",
                                  marginLeft: "10px",
                                }}
                              >
                                {aspect.countIdeasByAspect ?? 0}
                              </Box>
                            </Box>
                          );
                        })}
                      </Box>
                      {aspectSelectedId && <ListSuggestionAspect aspectId={aspectSelectedId} />}
                    </>
                  )}
                </Box>
              </Box>
            </LayoutTab>
          </Box>
        </Panel>
        <PanelResizeHandle
          style={{
            width: "5px",
            backgroundColor: "#e1e1e1",
          }}
        />
        <Panel
          defaultSize={30}
          minSize={50}
          style={{
            // width: "360px",
            minWidth: "320px",
          }}
        >
          <MyIdeaPath />
        </Panel>
      </PanelGroup>
    </Box>
  );
};

export default LearnQuestionPage;
