import { Box, Button } from "@mui/material";
import { ExerciseGramnmarResults } from "@/common";

const ResultsSummary: React.FC<{ results: ExerciseGramnmarResults; onTryAgain: () => void; onContinueAnswer: () => void }> = ({
  results,
  onTryAgain,
  onContinueAnswer,
}) => {
  const percentage = Math.round((results.totalScore / results.totalQuestions) * 100);

  return (
    <div className="results-summary">
      <h2>Final Results</h2>

      <div className="score-card">
        <div className="total-score">
          <div className="score-circle">
            <span className="percentage">{percentage}%</span>
          </div>
          <p>
            Total Score: {results.totalScore} / {results.totalQuestions}
          </p>
        </div>

        <div className="section-scores">
          <div className="score-item">
            <h3>Verb Forms</h3>
            <p>
              {results.verbForms.score} / {results.verbForms.total}
            </p>
          </div>
          <div className="score-item">
            <h3>Sentence Formation</h3>
            <p>
              {results.sentenceFormation.score} / {results.sentenceFormation.total}
            </p>
          </div>
        </div>

        <div className="feedback-message">
          {percentage === 100 && <p className="perfect">Perfect score! Outstanding work! 🎉</p>}
          {percentage >= 80 && percentage < 100 && <p className="excellent">Excellent work! Keep it up! 👏</p>}
          {percentage >= 60 && percentage < 80 && <p className="good">Good effort! Room for improvement. 👍</p>}
          {percentage < 60 && <p className="needs-practice">Keep practicing! You'll get better! 💪</p>}
        </div>

        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <Button onClick={onTryAgain} className="try-again-btn" variant="contained">
            Try again
          </Button>
          <Button onClick={onContinueAnswer} className="try-again-btn" variant="contained">
            Continue answer
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default ResultsSummary;
