import React, { useState } from "react";
import { IUserAnswersGrammar, SentenceFormationExerciseData } from "@/common";
import { Box, Typography } from "@mui/material";
import { green, red } from "@mui/material/colors";

interface Props {
  data: SentenceFormationExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswersGrammar>>;
}

const SentenceFormationExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [arrangedSentences, setArrangedSentences] = useState<Record<number, string[]>>({});

  const handleWordClick = (sentenceIndex: number, word: string): void => {
    setArrangedSentences((prev) => {
      const currentSentence = prev[sentenceIndex] || [];
      const newSentence = [...currentSentence, word];
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        sentenceFormation: {
          ...prevAnswers.sentenceFormation,
          [sentenceIndex]: newSentence,
        },
      }));
      return {
        ...prev,
        [sentenceIndex]: newSentence,
      };
    });
  };

  const removeWord = (sentenceIndex: number, wordIndex: number): void => {
    setArrangedSentences((prev) => {
      const newSentence = [...(prev[sentenceIndex] || [])];
      newSentence.splice(wordIndex, 1);
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        sentenceFormation: {
          ...prevAnswers.sentenceFormation,
          [sentenceIndex]: newSentence,
        },
      }));
      return {
        ...prev,
        [sentenceIndex]: newSentence,
      };
    });
  };

  return (
    <div className="exercise-section">
      <h2>Sentence Formation</h2>
      <p>{data.instructions}</p>

      {data.sentences.map((sentence, sentenceIndex) => (
        <div key={sentenceIndex} className="sentence-formation">
          <div className="word-bank">
            {`${sentenceIndex + 1}) `}
            {sentence.words.map((word, wordIndex) => (
              <button
                key={wordIndex}
                onClick={() => !isSubmitted && handleWordClick(sentenceIndex, word)}
                disabled={(arrangedSentences[sentenceIndex] || []).includes(word)}
              >
                {word}
              </button>
            ))}
          </div>

          <Box
            className="arranged-sentence"
            style={
              isSubmitted
                ? {
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px",
                  }
                : {}
            }
          >
            <Box>
              {(arrangedSentences[sentenceIndex] || []).map((word, wordIndex) => (
                <span
                  key={wordIndex}
                  className="arranged-word"
                  onClick={() => !isSubmitted && removeWord(sentenceIndex, wordIndex)}
                >
                  {word}
                </span>
              ))}
            </Box>
            {isSubmitted ? (
              arrangedSentences[sentenceIndex]?.join(" ")?.toLocaleLowerCase() !==
              sentence.answer?.trim()?.toLocaleLowerCase() ? (
                <Typography color={red[500]}>=&gt; Wrong: {sentence.answer}</Typography>
              ) : (
                <Typography color={green[500]}>=&gt; Correct!</Typography>
              )
            ) : null}
          </Box>
        </div>
      ))}
    </div>
  );
};

export default SentenceFormationExercise;
