import { onPlayReadStr } from "@/helpers";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, IconButton } from "@mui/material";
import { useEffect, useState } from "react";

interface IProps {
  text: string;
}

enum StatePlayAudio {
  INIT = "INIT",
  PLAY = "PLAY",
  PAUSE = "PAUSE",
}

const synth = window.speechSynthesis;

function PlayAudio({ text }: IProps) {
  const [stateAudio, setStateAudio] = useState<StatePlayAudio>(StatePlayAudio.INIT);

  const onClick = () => {
    synth.paused
      ? synth.resume()
      : synth.speaking
      ? synth.pause()
      : onPlayReadStr(text, () => {
          setStateAudio(StatePlayAudio.INIT);
        });
    if (stateAudio === StatePlayAudio.INIT) {
      setStateAudio(StatePlayAudio.PLAY);
    } else if (stateAudio === StatePlayAudio.PLAY) {
      setStateAudio(StatePlayAudio.PAUSE);
    } else {
      setStateAudio(StatePlayAudio.PLAY);
    }
  };

  useEffect(() => {
    return () => synth.cancel();
  }, []);

  return (
    <Box>
      <IconButton
        onClick={onClick}
        sx={(theme) => ({
          "& svg": {
            color: stateAudio !== StatePlayAudio.INIT ? theme.palette.primary.main : "none",
          },
        })}
      >
        {stateAudio === StatePlayAudio.PAUSE ? (
          <PauseIcon sx={{ width: "35px", height: "35px" }} />
        ) : (
          <PlayArrowIcon
            sx={{
              width: "35px",
              height: "35px",
            }}
          />
        )}
      </IconButton>
    </Box>
  );
}

export default PlayAudio;
