import { ROUTES } from "@/constants";
import { useDeleteVoca, useGetListVocabsByWordbook, useGetOneWordbook } from "@/services-react-query/queries/wordbank.query";
import { Box, Button, IconButton, LinearProgress, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, createSearchParams, useLocation, useNavigate, useParams } from "react-router-dom";
import ListVocabsGridView from "./components/ListVocabsGridView";
import RefreshIcon from '@mui/icons-material/Refresh';
import PlayWithFlashCard from "./components/PlayWithFlashCard";
import PlayVocaContainer from "./components/PlayVocaContainer";
import { EAction } from "@/common";
import { useDispatch } from "react-redux";
import { showMessage } from "@/store-redux";
import Swal from "sweetalert2";

const WordbookPage = (props) => {
    const dispatch = useDispatch()
    const params = useParams();
    const navigate = useNavigate();
    const location = useLocation()
    const [vocabs, setVocabs] = useState([]);
    const [dataWordbook, setDataWordbook] = useState(null)
    const { mutate: getListVocabsByWordbook, isLoading: loading } = useGetListVocabsByWordbook({
        onSuccess: (data: any) => {
            setVocabs(data)
        },
    });
    const searchParams = new URLSearchParams(location.search);
    const {gameType} = Object.fromEntries(searchParams);

    const { mutate: getOneWordbook, isLoading: isLoadingDataWordbook } = useGetOneWordbook({
        onSuccess: (data: any) => {
            setDataWordbook(data)
        },
    });

    const { mutate: deleteVoca, isLoading: isLoadingDeleteVoca } = useDeleteVoca({
        onSuccess: (id: any) => {
            setVocabs(prev => prev.filter(item => item?._id !== id))
            dispatch(showMessage('Delete vocabulary success', 'success'))
        },
    });
    useEffect(() => {
        onRefresh();
    }, [])

    const onRefresh = () => {
        getListVocabsByWordbook(params.wordbookId)
        getOneWordbook(params.wordbookId)
    }

    const onSelectGameType = (gameType) => {
        navigate({
            pathname: location.pathname,
            search: gameType ? createSearchParams({
                gameType
            }).toString() : ''
        })
    }

    const showGameContent = () => {
        if (gameType) {
            return (
                <PlayVocaContainer dataWordbook={dataWordbook} onClose={() => {
                    onSelectGameType(null)
                }}>
                    <PlayWithFlashCard items={vocabs}/>
                </PlayVocaContainer>
            )
        }
        return (<></>)
    }

    const onClickAction = (typeAction, item) => {
        if (typeAction === EAction.delete) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
              }).then((result) => {
                if (result.isConfirmed) {
                    console.log('deleted', item)
                    deleteVoca(item._id)
                }
              });
        }
        // console.log(typeAction, item)
    }

    return (
        <Box
            sx={() => ({
                padding: "16px",
                height: "calc(100vh - 56px)",
                "& h2": {
                    height: "54px",
                    fontSize: "36px",
                    fontWeight: "700",
                    lineHeight: "54px",
                    marginRight: '10px'
                },
            })}
        >
            <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                    <Typography component={"h2"} className="text" sx={{
                        '& span': {
                            fontWeight: 500,
                            marginLeft: '10px',
                            fontSize: 24
                        }
                    }}>
                        Wordbook
                        {dataWordbook && <span>{dataWordbook?.name}</span>}
                    </Typography>
                    <IconButton onClick={() => onRefresh()}>
                        <RefreshIcon />
                    </IconButton>
                </Box>
                <NavLink to={ROUTES.WORDBANK}>
                    <Button sx={{
                        minWidth: "68px",
                        height: "30px",
                        padding: "0px 8px",
                        borderRadius: "4px",
                        color: "white",
                        backgroundColor: "#264653",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "30px",
                        textTransform: "none",
                    }} variant="contained">
                        Back
                    </Button>
                </NavLink>
            </Box>
            {loading && <LinearProgress />}
            <Button sx={{
                        minWidth: "68px",
                        height: "30px",
                        padding: "0px 8px",
                        borderRadius: "4px",
                        color: "white",
                        backgroundColor: "#264653",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 700,
                        lineHeight: "30px",
                        textTransform: "none",
                    }} variant="contained" onClick={() => onSelectGameType('flashcard')}>
                Play with Flash Card
            </Button>
            
            {showGameContent()}
            <Box>
                <ListVocabsGridView items={vocabs} handleClickAction={(typeAction, item) => onClickAction(typeAction, item)}/>
            </Box>
        </Box>
    )
}

export default WordbookPage;