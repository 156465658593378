import { Box, Button, CircularProgress, colors, Dialog, IconButton, Typography } from "@mui/material";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ExerciseData, IApplicationState } from "@/store-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useGenerateVocabularyPractice } from "@/services-react-query/queries";
import VocabularyPracticeModal from "./VocabularyPracticeModal";

function VocabularyPractice() {
  const [openModal, setOpenModal] = useState(false);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const [listText, setListText] = useState<string[]>([]);

  const [openPracticeModal, setOpenPracticeModal] = useState(false);
  const [exerciseData, setExerciseData] = useState<ExerciseData | undefined>(undefined);

  const { mutate: generateVocabulary, isLoading: generateVocabularyLoading } = useGenerateVocabularyPractice({
    onSuccess(data) {
      onClose();
      setExerciseData(data?.data?.result);
      setTimeout(() => {
        setOpenPracticeModal(true);
      }, 100);
    },
  });

  useEffect(() => {
    setTimeout(() => {
      const handleMouseUp = () => {
        const selectedText = (window?.getSelection()?.toString() ?? "").trim();
        if (selectedText !== "") {
          setListText((prev) => {
            const existed = prev.find((item) => item === selectedText.trim().toLocaleLowerCase());
            if (existed) {
              return prev;
            }
            return [...prev, selectedText.trim().toLocaleLowerCase()];
          });
        }
      };
      const editorEl = document.getElementById("sentence_vocabulary");
      editorEl?.addEventListener("mouseup", handleMouseUp);
      return () => {
        editorEl?.removeEventListener("mouseup", handleMouseUp);
      };
    }, 2000);
  }, []);

  const onClose = () => {
    setListText([]);
    setOpenModal(false);
  };

  const onGenerate = () => {
    generateVocabulary({
      listVocabs: listText,
      sentence: editCustomAnswerContent.data,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setOpenModal(true);
        }}
        color="warning"
      >
        <FitnessCenterIcon />
        Vocabulary Practice
      </Button>
      <Dialog
        open={true}
        onClose={onClose}
        style={{
          display: openModal ? "block" : "none",
        }}
      >
        <Box
          sx={{
            padding: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              marginBottom: "32px",
              color: colors.blue[500],
              textAlign: "center",
              " span": {
                fontSize: "18px",
              },
            }}
          >
            <Typography component={"span"}>Please choose a word you want to practice ( minimum 4 words )</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              border: "1px solid gray",
              borderBottom: "none",
              "&>div": {
                flex: 1,
                padding: "8px",
              },
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid gray",
              }}
            >
              Your sentence
            </Box>
            <Box>Your word is selected</Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              border: "1px solid gray",
              "&>div": {
                flex: 1,
                padding: "8px",
              },
            }}
          >
            <Box
              sx={{
                borderRight: "1px solid gray",
              }}
              id="sentence_vocabulary"
            >
              {editCustomAnswerContent.data.toString()}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {listText?.map((item, index: number) => (
                <Box key={index}>
                  <Typography component={"span"}>{item}</Typography>
                  <IconButton
                    sx={{
                      marginLeft: "6px",
                    }}
                    onClick={() => {
                      setListText((prev) => prev.filter((i) => i !== item));
                    }}
                  >
                    <CloseIcon color="error" />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
              "& button": {
                textTransform: "none",
              },
            }}
            onClick={onGenerate}
          >
            <Button variant="contained" disabled={generateVocabularyLoading}>
              {generateVocabularyLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    marginRight: "8px",
                  }}
                />
              )}
              Generate practice
            </Button>
          </Box>
        </Box>
      </Dialog>
      <VocabularyPracticeModal
        exerciseData={exerciseData}
        openModal={openPracticeModal}
        setOpenModal={setOpenPracticeModal}
      />
    </>
  );
}

export default VocabularyPractice;
