import { onPlayReadStr } from "@/helpers";
import { ENDPOINTS, getApiUrl, IApplicationState, Requests } from "@/store-redux";
import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { memo, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

const TabMainIdea = () => {
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const [data, setData] = useState([]);
  const sentences = useMemo(() => {
    return (editCustomAnswerContent?.data ?? "")
      .split(".")
      .filter((item) => item.trim() !== "")
      .map((item) => item.trim());
  }, [editCustomAnswerContent]);

  useEffect(() => {
    getMainIdeaKeyword();
  }, []);

  const getMainIdeaKeyword = async () => {
    try {
      const apiUrl = getApiUrl(ENDPOINTS.PRACTICE_GET_MAIN_IDEA);
      const data = await Requests.post(apiUrl, {
        sentences,
      });
      if (Array.isArray(data)) {
        setData(data);
      }
    } catch (e) {
      console.log("e", e);
    }
  };
  return (
    <Box
      sx={{
        maxHeight: "calc(100vh - 220px)",
        overflowY: "auto",
      }}
    >
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                fontWeight: "bold",
                width: "50%",
              }}
            >
              Sentence
            </TableCell>
            <TableCell
              sx={{
                fontWeight: "bold",
                width: "50%",
              }}
            >
              Main idea & keyword
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sentences.map((sen, index) => {
            const item = data[index];
            return (
              <TableRow>
                <TableCell>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      onPlayReadStr(sen);
                    }}
                  >
                    {sen}
                  </Typography>
                </TableCell>
                <TableCell>
                  {item ? (
                    <>
                      <Typography
                        sx={{
                          "& span": {
                            fontStyle: "italic",
                            fontSize: "14px",
                            cursor: "pointer",
                          },
                        }}
                      >
                        <b>Main dea :</b>{" "}
                        <span
                          onClick={() => {
                            onPlayReadStr(item.main_idea ?? "");
                          }}
                        >
                          {item.main_idea ?? ""}
                        </span>
                      </Typography>
                      <Typography>
                        <b>Keywords :</b>
                      </Typography>
                      {Array.isArray(item.keywords) && (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                          }}
                        >
                          {item.keywords.map((keyword) => {
                            return (
                              <Box
                                sx={{
                                  margin: "5px 10px",
                                  fontStyle: "italic",
                                  color: "#6A1E55",
                                  fontSize: "14px",
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  onPlayReadStr(keyword ?? "");
                                }}
                              >
                                {keyword}
                              </Box>
                            );
                          })}
                        </Box>
                      )}
                    </>
                  ) : (
                    <Skeleton variant="rectangular" width={"100%"} height={50} />
                  )}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default memo(TabMainIdea);
