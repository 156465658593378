import robotAIJson from "@/assets/lottie-json/robot_ai.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button } from "@mui/material";
import Lottie from "lottie-react";
import { useState } from "react";
import ChooseSpeed from "./ChooseSpeed";
import RobotReadingQuestion from "./RobotReadingQuestion";
import SpeakingSpeed from "./SpeakingSpeed";

enum ETabSpeakingSpeed {
  CHOOSE_SPEED = "CHOOSE_SPEED",
  ROBOT_READ_QUESTION = "ROBOT_READ_QUESTION",
  SPEAKING_SPEECH = "SPEAKING_SPEECH",
}

function TabSpeakingSpeed() {
  const [currentStep, setCurrentStep] = useState<ETabSpeakingSpeed>(ETabSpeakingSpeed.CHOOSE_SPEED);
  const [speed, setSpeed] = useState(1);

  const handleGoBack = () => {
    switch (currentStep) {
      case ETabSpeakingSpeed.SPEAKING_SPEECH:
        setCurrentStep(ETabSpeakingSpeed.CHOOSE_SPEED);
        break;
      default:
        break;
    }
  };
  return (
    <Box>
      {currentStep === ETabSpeakingSpeed.SPEAKING_SPEECH && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        </Box>
      )}

      <Lottie
        animationData={robotAIJson}
        loop={true}
        style={{
          height: "150px",
          marginTop: "-20px",
        }}
      />

      {currentStep === ETabSpeakingSpeed.CHOOSE_SPEED ? (
        <ChooseSpeed
          speed={speed}
          setSpeed={setSpeed}
          onNext={() => setCurrentStep(ETabSpeakingSpeed.ROBOT_READ_QUESTION)}
        />
      ) : currentStep === ETabSpeakingSpeed.ROBOT_READ_QUESTION ? (
        <RobotReadingQuestion onNext={() => setCurrentStep(ETabSpeakingSpeed.SPEAKING_SPEECH)} />
      ) : currentStep === ETabSpeakingSpeed.SPEAKING_SPEECH ? (
        <SpeakingSpeed speed={speed} />
      ) : null}
    </Box>
  );
}

export default TabSpeakingSpeed;
