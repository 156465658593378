import { matchedLetterAnswer, onPlayReadStr, removedLetterAnswer } from "@/helpers";
import WordDetail from "@/pages/LearnPage/components/WordSearch/WordDetail";
import { IApplicationState } from "@/store-redux";
import { Box, Button, colors, styled, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";

interface ICustomAnswerWordBoldProps {
  nextStep: () => void;
  boldIndexes: number[];
  setBoldIndexes: React.Dispatch<React.SetStateAction<number[]>>;
  dataWordSearch: any;
  isFetchedWordSearch: boolean;
  showIpa: boolean;
}

const LightTooltip = styled(
  ({
    className,
    title,
    children,
    ...props
  }: {
    className?: string;
    title: React.ReactNode;
    children: React.ReactElement;
  }) => (
    <Tooltip {...props} classes={{ popper: className }} title={title}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function CustomAnswerWordBold({
  nextStep,
  boldIndexes,
  setBoldIndexes,
  dataWordSearch,
  isFetchedWordSearch,
  showIpa,
}: ICustomAnswerWordBoldProps) {
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);

  const wordArray = removedLetterAnswer(editCustomAnswerContent.data);

  const handleWordBold = (index: number) => {
    //  tạo một state để lưu vị trí sẽ bold
    // check trạng thái khi clịck vào rồi thì cái index có thuộc array hay k nêu có thì xoá đi k có thì thêm vào
    // một phần tử thuộc một mảng (include ) nếu phần tử có trong mảng thì bold
    // next sang highlight truyền một chuỗi string thêm thẻ b

    wordArray[index] && onPlayReadStr(wordArray[index]);

    if (boldIndexes.includes(index)) {
      setBoldIndexes(boldIndexes.filter((i) => i !== index));
    } else {
      setBoldIndexes([...boldIndexes, index]);
    }
  };

  const matches = matchedLetterAnswer(editCustomAnswerContent.data);

  useEffect(() => {
    const editorWrapperEl = document.getElementById("box_answer-highlight");
    const handleMouseUp = () => {
      const selectedText = (window?.getSelection()?.toString() ?? "").trim()?.replace(/\s+/g, " ");
      if (selectedText !== "") {
        onPlayReadStr(selectedText);
      }
    };
    editorWrapperEl?.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchend", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, []);

  return (
    <Box
      sx={{
        maxWidth: "calc(100vw - 32px)",
        height: "calc(100vh - 250px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Box
          component={"ul"}
          sx={{
            listStyle: "none",
            display: "flex",
            flex: "row",
            justifyContent: "space-evenly",
            marginTop: "20px",
            border: "1px solid black",
            padding: "5px 0",
            borderRadius: "8px",
            "& li": {},
          }}
        >
          {matches?.map((item, idx) => (
            <Box component={"li"} key={idx}>
              {item.replace(/[()]/g, "")}
            </Box>
          ))}
        </Box>
        <div id="box_answer-highlight">
          <Box
            sx={{
              marginTop: "20px",
              overflow: "auto",
              maxHeight: "72vh",
              display: "flex",
              flexDirection: "row",
              maxWidth: "calc(100vw - 32px)",
              flexFlow: "row wrap",
            }}
          >
            {wordArray.map((singleWord, index) => {
              if (index === wordArray.length) return null;
              const dataFind =
                isFetchedWordSearch &&
                dataWordSearch?.items.find(
                  (item: any) => item.word === singleWord?.trim()?.replace(/[,.]/g, "")?.toLocaleLowerCase()
                );
              const ipa = dataFind?.phonetic?.us;
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginBottom: "20px",
                  }}
                >
                  {isFetchedWordSearch && showIpa ? (
                    <span
                      style={{
                        fontSize: 18,
                        fontStyle: "italic",
                        color: colors.grey[700],
                        userSelect: "none",
                        WebkitUserSelect: "none",
                        MozUserSelect: "none",
                        msUserSelect: "none",
                      }}
                    >
                      {ipa ?? "---"}
                    </span>
                  ) : null}
                  <LightTooltip title={<WordDetail word={dataFind} />}>
                    <Typography
                      sx={{
                        fontWeight: boldIndexes.includes(index) ? "600" : "400",
                        paddingInline: "3px",
                        color: boldIndexes.includes(index) ? "red" : "black",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: "#ECEAEA",
                        },
                        userSelect: "text",
                        fontSize: "20px",
                      }}
                      component="span"
                      onClick={() => {
                        handleWordBold(index);
                      }}
                    >
                      {singleWord + " "}
                    </Typography>
                  </LightTooltip>
                </Box>
              );
            })}
          </Box>
        </div>
      </Box>
      <Button
        sx={{
          width: "100%",
          textTransform: "none",
          fontSize: "16px",
          fontWeight: "700",
        }}
        variant="outlined"
        onClick={() => {
          nextStep();
        }}
      >
        Highlight
      </Button>
    </Box>
  );
}

export default CustomAnswerWordBold;
