import { Box, Slider, Typography } from "@mui/material";
import { useState } from "react";

function PitchAudio() {
  const [value, setValue] = useState<number>(
    localStorage.getItem("pitch_audio") ? Number(localStorage.getItem("pitch_audio")) : 100
  );

  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number);
    localStorage.setItem("pitch_audio", newValue.toString());
  };

  return (
    <Box width={200}>
      <Typography
        component={"span"}
        sx={{
          fontSize: 14,
        }}
      >
        Pitch: <b>{value.toFixed(0)}</b>
      </Typography>
      <Slider value={value} min={1} max={200} step={10} onChange={handleChange} valueLabelDisplay="auto" color="info"/>
    </Box>
  );
}

export default PitchAudio;
