import { ENDPOINTS, IAnalyzeGrammar, IItems, IPracticeGrammar, Requests, getApiUrl } from "@/common";


export const analyzeGrammarSentence = async (payload: IAnalyzeGrammar): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.GRAMMAR_ANALYZE_SENTENCE);
  const data: any = await Requests.post(apiUrl, payload);
  return data;
};

export const generateGrammarPractice = async (payload: IPracticeGrammar): Promise<IItems> => {
  const apiUrl = getApiUrl(ENDPOINTS.GRAMMAR_GENERATE_PRACTICE);
  const data: any = await Requests.post(apiUrl, payload);
  return data;
};