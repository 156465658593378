import waveRecordingJson from "@/assets/lottie-json/wave_recording.json";
import { LLMTypes } from "@/constants";
import { matchedLetterAnswer, removedLetterAnswer } from "@/helpers";
import {
  useGetListEnergyPrice,
  usePracticeTranscribe,
  useUploadFilePractice,
  useWordMatchEvaluate,
} from "@/services-react-query/queries";
import { IApplicationState, IWordMatchOutput } from "@/store-redux";
import { Box, Button, LinearProgress, Typography, useTheme } from "@mui/material";
import Lottie from "lottie-react";
import { useCallback, useEffect, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useSelector } from "react-redux";

interface IProps {
  speed: number;
  boldIndexes: number[];
}

function SpeakingSpeed({ speed, boldIndexes }: IProps) {
  const theme = useTheme();
  const detailQuestion = useSelector((state: IApplicationState) => state.detailItem);
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const [index, setIndex] = useState(0);
  const [printedWords, setPrintedWords] = useState<string[]>([]);

  const wordArray = removedLetterAnswer(editCustomAnswerContent.data);
  const matches = matchedLetterAnswer(editCustomAnswerContent.data);

  const { data: listEnergyPrice } = useGetListEnergyPrice({});

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (index < wordArray.length) {
        const textVal = boldIndexes.includes(index) ? wordArray[index] : `${wordArray[index].replace(/\w/g, "_")}`;
        setPrintedWords((prevWords) => [...prevWords, textVal]);
        setIndex(index + 1);
      }
    }, 500 * (1 / speed));

    return () => clearTimeout(timeoutId);
  }, [boldIndexes, index, speed, wordArray]);

  const [audioRecord, setAudioRecord] = useState<any>();
  const [isSubmit, setIsSubmit] = useState(false);
  const [checkMatch, setCheckMatch] = useState<IWordMatchOutput["gptEvaluates"] | null>(null);

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );
  const addAudioElement = (blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setAudioRecord(url);
  };

  const { mutate: uploadFileAudio, isLoading: loadingUploadFile } = useUploadFilePractice({
    onSuccess(data) {
      if (data?._id) {
        postTranscribe(data?._id);
      }
    },
  });

  const { mutate: postTranscribe, isLoading: loadingTranscribe } = usePracticeTranscribe({
    onSuccess(data) {
      if (data?._id) {
        postEvaluate({
          id: data?._id,
          original_answer: wordArray?.join(" "),
        });
      }
    },
  });

  const { mutate: postEvaluate, isLoading: loadingEvaluate } = useWordMatchEvaluate({
    onSuccess(values) {
      values?.gptEvaluates && setCheckMatch(values?.gptEvaluates);
    },
  });

  const handleSendAudioAnswer = useCallback(async () => {
    const blob = await (await fetch(audioRecord)).blob();
    const timeStamp = new Date(Date.now()).toISOString().replace(/:/g, "-").replace(/\./g, "-");
    const file = new File([blob], `${timeStamp}.webm`, { type: "audio/webm" });

    detailQuestion?.data?._id &&
      uploadFileAudio({
        questionId: detailQuestion?.data?._id ?? "",
        voiceFile: file,
      });
  }, [audioRecord, detailQuestion?.data?._id, uploadFileAudio]);

  useEffect(() => {
    if (index === wordArray.length) {
      setTimeout(() => {
        recorderControls.stopRecording();
        setIsSubmit(true);
      }, 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  useEffect(() => {
    recorderControls.startRecording();

    return () => recorderControls?.stopRecording();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    handleSendAudioAnswer();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: "none" }}>
          <AudioRecorder onRecordingComplete={(blob) => addAudioElement(blob)} recorderControls={recorderControls} />
        </Box>
        {loadingUploadFile || loadingTranscribe || loadingEvaluate ? (
          <Box
            sx={{
              marginTop: "24px",
              marginBottom: "16px",
              width: "100%",
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
              }}
            >
              {loadingUploadFile ? (
                "The audio is being processing..."
              ) : loadingTranscribe ? (
                <Typography
                  sx={{
                    display: "flex",
                  }}
                >
                  Transcribing...
                  <Typography sx={{ fontWeight: "bold" }}>({listEnergyPrice?.types?.[LLMTypes.transcribe]} </Typography>
                  )
                </Typography>
              ) : (
                <Typography
                  sx={{
                    display: "flex",
                  }}
                >
                  Evaluating...
                  <Typography sx={{ fontWeight: "bold" }}>({listEnergyPrice?.types?.[LLMTypes.transcribe]} </Typography>
                  )
                </Typography>
              )}
            </Typography>
            <LinearProgress
              color="success"
              sx={{}}
              valueBuffer={loadingUploadFile ? 30 : loadingTranscribe ? 70 : loadingEvaluate ? 100 : 0}
            />
          </Box>
        ) : null}
        {recorderControls.isRecording ? (
          <Lottie
            animationData={waveRecordingJson}
            loop
            style={{
              height: "150px",
              marginBlock: "-40px",
            }}
          />
        ) : null}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            marginTop: "20px",
            border: "1px solid black",
            padding: "5px 0",
            borderRadius: "8px",
            marginBottom: "16px",
            minWidth: "300px",
            "& span": {
              cursor: "pointer",
              padding: "0 12px",
              "&:hover": {
                backgroundColor: "#ECEAEA",
              },
            },
          }}
        >
          {matches?.map((item, idx) => (
            <Typography
              component={"span"}
              key={idx}
              sx={{
                fontWeight: idx === printedWords?.join("").split(".").length - 1 ? "700" : "400",
                color: idx === printedWords?.join("").split(".").length - 1 ? theme.palette.primary.main : "",
              }}
            >
              {item.replace(/[()]/g, "")}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            fontSize: "24px",
          }}
        >
          {printedWords.join(" ")}
        </Box>
        {isSubmit ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "10px",
              "& video": {
                marginTop: "-60px",
              },
            }}
          >
            <video src={audioRecord} controls playsInline />

            {!checkMatch ? (
              <Button
                variant="contained"
                onClick={onSubmit}
                sx={{
                  minWidth: "68px",
                  height: "40px",
                  padding: "6px 16px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  fontWeight: 700,
                  textTransform: "none",
                  "& svg": {
                    marginRight: "4px",
                  },
                }}
              >
                Submit
              </Button>
            ) : null}
          </Box>
        ) : null}
        {checkMatch ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "16px",
              padding: "16px",
              backgroundColor: "#F7F7F7",
              borderRadius: "8px",
            }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: "30px",
              }}
              component={"span"}
            >
              {(Number(checkMatch?.percentage ?? "0") * 100).toFixed(2)} %
            </Typography>
            <Box
              dangerouslySetInnerHTML={{ __html: checkMatch?.bold_word_wrong || "" }}
              sx={{
                "& b": {
                  color: "red",
                },
              }}
            />
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}

export default SpeakingSpeed;
