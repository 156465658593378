import { useMutation } from "react-query";
import { IQueryOptions } from ".";
import { analyzeGrammarSentence, generateGrammarPractice } from "../api";

export const useAnalyzeGrammarSentence = (options: IQueryOptions) => {
  return useMutation(analyzeGrammarSentence, options);
};

export const useGenerateGrammarPractice = (options: IQueryOptions) => {
  return useMutation(generateGrammarPractice, options);
};
