import React, { useState } from "react";
import { FillBlanksExerciseData, IUserAnswers } from "@/common";
import { green, red } from "@mui/material/colors";
import { Box, Typography } from "@mui/material";

interface Props {
  data: FillBlanksExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswers>>;
}

const FillBlanksExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [answers, setAnswers] = useState<Record<string, string>>({});

  const handleChange = (sentenceIndex: number, blankIndex: number, value: string): void => {
    const newAnswers = {
      ...answers,
      [`${sentenceIndex}-${blankIndex}`]: value.toLowerCase(),
    };
    setAnswers(newAnswers);
    setUserAnswers((prev: IUserAnswers) => {
      const updatedFillBlanks = {
        ...prev.fillBlanks,
        ...newAnswers,
      };
      return {
        ...prev,
        fillBlanks: updatedFillBlanks,
      };
    });
  };

  return (
    <div className="exercise-section fill-blanks">
      <h2>Fill in the Blanks</h2>
      <p>{data.instructions}</p>

      {data?.word_bank ? (
        <div className="word-bank">
          <strong>Use the following words:</strong> {data.word_bank?.join(", ")}
        </div>
      ) : null}

      <div className="sentences">
        {data.sentences.map((sentence, sentenceIndex) => {
          const parts = sentence.sentence.split("_____");
          return (
            <div key={sentenceIndex} className="sentence">
              {`${sentenceIndex + 1}) `}
              {parts.map((part, index) => (
                <React.Fragment key={index}>
                  {part}
                  {index < parts.length - 1 && (
                    <Box
                      sx={
                        isSubmitted && !sentence.answers.includes(answers[`${sentenceIndex}-${index}`]?.toLowerCase())
                          ? {
                              display: "flex",
                              gap: "4px",
                              alignItems: "center",
                            }
                          : {}
                      }
                    >
                      <input
                        type="text"
                        onChange={(e) => handleChange(sentenceIndex, index, e.target.value)}
                        value={answers[`${sentenceIndex}-${index}`] || ""}
                        style={{
                          backgroundColor: isSubmitted
                            ? sentence.answers.includes(answers[`${sentenceIndex}-${index}`]?.toLowerCase())
                              ? green[500] // green for correct
                              : red[500] // red for incorrect
                            : "white",
                        }}
                        disabled={isSubmitted}
                      />
                      {isSubmitted &&
                      !sentence.answers.includes(answers[`${sentenceIndex}-${index}`]?.toLowerCase()) ? (
                        <Typography component={"span"} color={green[500]}>
                          =&gt; {data.sentences[sentenceIndex].answers[index]}
                        </Typography>
                      ) : null}
                    </Box>
                  )}
                </React.Fragment>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FillBlanksExercise;
