import { useMutation, useQuery } from "react-query";
import { IQueryOptions } from ".";
import { ScoreBand } from "@/common";
import { fetchDictionary, fetchVocaQuestion, generateVocabularyPractice } from "../api";

export interface IGetVocaQuestionPayload {
  query: {
    questionId: string;
    band: ScoreBand | null;
  };
  options?: IQueryOptions;
}

export const useGetVocaQuestion = (payload: IGetVocaQuestionPayload) => {
  const { options = {}, query } = payload;
  return useQuery(["fetchVocaQuestion"], () => fetchVocaQuestion(query), {
    keepPreviousData: true,
    ...options,
  });
};

export interface IGetDictionary {
  query: {
    keyword: string;
  };
  options?: IQueryOptions;
}


export const useGetDictionary = (payload: IGetDictionary) => {
  const { options = {}, query } = payload;
  return useQuery(["fetchDictionary"], () => fetchDictionary(query), {
    keepPreviousData: true,
    ...options,
  });
};

export const useGenerateVocabularyPractice = (options: IQueryOptions) => {
  return useMutation(generateVocabularyPractice, options);
};
