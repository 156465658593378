import { Box, Button, Switch, Typography } from "@mui/material";
import { useState } from "react";
import CustomAnswerWordBold from "./CustomAnswerWordBold";
import CustomAnswerHighlight from "./CustomHighlight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IApplicationState } from "@/store-redux";
import { useSelector } from "react-redux";
import { removedLetterAnswer } from "@/helpers";
import PlayAudio from "@/components/PlayAudio";
import { useGetDictionary } from "@/services-react-query/queries";
import SpeedAudio from "@/components/SpeedAudio";
import PitchAudio from "@/components/PitchAudio";

enum ECurrentStepPractice {
  ANSWER_WORD_BOLD = "ANSWER_WORD_BOLD",
  ANSWER_HIGHLIGHT = "ANSWER_HIGHLIGHT",
}

interface IProps {
  boldIndexes: number[];
  setBoldIndexes: React.Dispatch<React.SetStateAction<number[]>>;
}

function TabHighlight({ boldIndexes, setBoldIndexes }: IProps) {
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const wordArray = removedLetterAnswer(editCustomAnswerContent.data);

  const [currentStep, setCurrentStep] = useState<ECurrentStepPractice>(ECurrentStepPractice.ANSWER_WORD_BOLD);
  const [showIpa, setShowIpa] = useState(true);

  const handleGoBack = () => {
    switch (currentStep) {
      case ECurrentStepPractice.ANSWER_WORD_BOLD:
        break;
      case ECurrentStepPractice.ANSWER_HIGHLIGHT:
        setCurrentStep(ECurrentStepPractice.ANSWER_WORD_BOLD);
        break;
      default:
        break;
    }
  };

  const genDataQuery = (): string => {
    if (wordArray?.length) {
      const dataSearch = wordArray.map((item) => {
        return item.replace(/[,.]/g, "")?.toLocaleLowerCase();
      });
      const uniqueDataSearch = [...new Set(dataSearch)];
      return uniqueDataSearch.join(",");
    }
    return "";
  };
  const { data: dataWordSearch, isFetched: isFetchedWordSearch } = useGetDictionary({
    query: {
      keyword: genDataQuery(),
    },
    options: {},
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <PitchAudio />
        <SpeedAudio />
        {currentStep === ECurrentStepPractice.ANSWER_WORD_BOLD ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              component="span"
              sx={{
                fontSize: "14px",
                marginTop: "4px",
              }}
            >
              Show IPA
            </Typography>
            <Switch checked={showIpa} onChange={() => setShowIpa(!showIpa)} />
          </Box>
        ) : null}
        <PlayAudio text={wordArray?.length ? wordArray?.join(" ") : ""} />
        {currentStep === ECurrentStepPractice.ANSWER_HIGHLIGHT && (
          <Button
            variant="outlined"
            onClick={handleGoBack}
            sx={{
              minWidth: "68px",
              height: "40px",
              padding: "6px 16px",
              borderRadius: "4px",
              fontSize: "16px",
              fontWeight: 700,
              textTransform: "none",
              "& svg": {
                marginRight: "4px",
              },
            }}
          >
            <ArrowBackIcon />
            Back
          </Button>
        )}
      </Box>
      {currentStep === ECurrentStepPractice.ANSWER_WORD_BOLD ? (
        <CustomAnswerWordBold
          nextStep={() => {
            setCurrentStep(ECurrentStepPractice.ANSWER_HIGHLIGHT);
          }}
          boldIndexes={boldIndexes}
          setBoldIndexes={setBoldIndexes}
          dataWordSearch={dataWordSearch}
          isFetchedWordSearch={isFetchedWordSearch}
          showIpa={showIpa}
        />
      ) : (
        <CustomAnswerHighlight
          boldIndexes={boldIndexes}
          dataWordSearch={dataWordSearch}
          isFetchedWordSearch={isFetchedWordSearch}
        />
      )}
    </Box>
  );
}

export default TabHighlight;
