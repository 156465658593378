import { ExerciseData, ExerciseResults, IUserAnswers } from "@/common";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, IconButton } from "@mui/material";
import React, { useState } from "react";
import FillBlanksExercise from "./components/FillBlanksExercise";
import MatchingExercise from "./components/MatchingExercise";
import ResultsSummary from "./components/ResultsSummary";
import "./styles.scss";
import MakeSentenceKeyword from "./components/MakeSentenceKeyword";
import AddVocabulary from "@/components/AddVocabulary";

// const exerciseData = {
//   matching: {
//     instructions: "Match the Vietnamese words with their English equivalents",
//     pairs: [
//       {
//         vietnamese: "chỗ ở",
//         english: "accommodation",
//       },
//       {
//         vietnamese: "tại chỗ",
//         english: "on-site",
//       },
//       {
//         vietnamese: "cơ sở thể dục",
//         english: "fitness facilities",
//       },
//       {
//         vietnamese: "thói quen",
//         english: "routine",
//       },
//     ],
//   },
//   fill_in_blanks: {
//     instructions: "Complete the sentences using the words provided",
//     word_bank: ["accommodation", "on-site", "fitness facilities", "routine"],
//     sentences: [
//       {
//         sentence: "My choice of _____ was influenced by the availability of a gym.",
//         answers: ["accommodation"],
//       },
//       {
//         sentence: "I prefer living in a place with _____ fitness options.",
//         answers: ["on-site"],
//       },
//       {
//         sentence: "The _____ at my apartment include a gym and a swimming pool.",
//         answers: ["fitness facilities"],
//       },
//       {
//         sentence: "I maintain my exercise _____ by working out every morning.",
//         answers: ["routine"],
//       },
//     ],
//   },
//   make_sentence_from_words: {
//     instructions: "Make sentences using the words provided",
//     questions: [
//       {
//         keywords: ["accommodation", "fitness facilities", "routine"],
//         answer: "My accommodation includes fitness facilities, which help me maintain my exercise routine.",
//       },
//       {
//         keywords: ["on-site", "gym", "routine"],
//         answer: "Having an on-site gym allows me to stick to my daily workout routine.",
//       },
//     ],
//   },
// };

interface IVocabularyPracticeModalProps {
  exerciseData?: ExerciseData;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

function VocabularyPracticeModal({ exerciseData, openModal, setOpenModal }: IVocabularyPracticeModalProps) {
  const [results, setResults] = useState<ExerciseResults>({
    totalScore: 0,
    totalQuestions: 0,
    matching: { score: 0, total: 0, answers: {} },
    fillBlanks: { score: 0, total: 0, answers: {} },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userAnswers, setUserAnswers] = useState<IUserAnswers>({
    matching: {},
    fillBlanks: {},
  });

  if (!exerciseData) return <></>;

  const checkMatchingAnswers = () => {
    const score = exerciseData.matching.pairs.reduce((acc, pair) => {
      const userAnswer = (userAnswers.matching as Record<string, string>)?.[pair.vietnamese] || "";
      return userAnswer === pair.english ? acc + 1 : acc;
    }, 0);

    return {
      score,
      total: exerciseData.matching.pairs.length,
      answers: userAnswers.matching || {},
    };
  };

  const checkFillBlanksAnswers = () => {
    let score = 0;
    let total = 0;

    exerciseData.fill_in_blanks.sentences.forEach((sentence, sentenceIndex) => {
      sentence.answers.forEach((answer, blankIndex) => {
        total++;
        const userAnswer: any =
          userAnswers.fillBlanks?.[`${sentenceIndex}-${blankIndex}` as keyof typeof userAnswers.fillBlanks];
        if (typeof userAnswer === "string" && userAnswer.toLowerCase() === answer.toLowerCase()) {
          score++;
        }
      });
    });

    return {
      score,
      total,
      answers: userAnswers.fillBlanks || {},
    };
  };

  const calculateResults = () => {
    const newResults = {
      matching: checkMatchingAnswers(),
      fillBlanks: checkFillBlanksAnswers(),
    };

    const totalScore = Object.values(newResults).reduce((acc, curr) => acc + curr.score, 0);
    const totalQuestions = Object.values(newResults).reduce((acc, curr) => acc + curr.total, 0);

    setResults({
      ...newResults,
      totalScore,
      totalQuestions,
    });
    setIsSubmitted(true);
  };

  return (
    <Dialog open={openModal} onClose={() => setOpenModal(false)} fullScreen>
      <Box
        sx={{
          padding: "32px",
        }}
        className="free-learn-to-idea__container"
      >
        <Box
          sx={{
            position: "fixed",
            right: "30px",
            top: "20px",
          }}
        >
          <IconButton
            size="small"
            sx={{
              backgroundColor: "gray",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "gray",
              },
            }}
            onClick={() => {
              setOpenModal(false);
            }}
          >
            <CloseIcon
              fontSize="small"
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
        </Box>
        <div className="app-container">
          <h1>Vocabulary Exercises</h1>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              calculateResults();
            }}
          >
            <div className="exercises-wrapper">
              <MatchingExercise
                data={exerciseData.matching}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <FillBlanksExercise
                data={exerciseData.fill_in_blanks}
                isSubmitted={isSubmitted}
                setUserAnswers={setUserAnswers}
              />
              <MakeSentenceKeyword data={exerciseData.make_sentence_from_words} isSubmitted={isSubmitted} />

              {!isSubmitted ? (
                <Button type="submit" variant="contained" className="submit-all-btn">
                  Submit all answers
                </Button>
              ) : (
                <ResultsSummary
                  results={results}
                  onTryAgain={() => {
                    setIsSubmitted(false);
                    setUserAnswers({
                      matching: {},
                      fillBlanks: {},
                    });
                  }}
                  onContinueAnswer={() => {
                    setIsSubmitted(false);
                    setOpenModal(false);
                  }}
                />
              )}
            </div>
          </form>
        </div>
      </Box>
      <AddVocabulary />
    </Dialog>
  );
}

export default VocabularyPracticeModal;
