import { ENDPOINTS, getApiUrl, Requests } from "@/common";
import { randomStringFromDatetime } from "@/helpers";
import { Box, Button, CircularProgress, Input } from "@mui/material";
import { useMemo, useState } from "react";
import ListIdeaAI from "./ListIdeaAI";

interface AnswerAspectToIdeaProps {
  aspects: any[];
  question: string;
}
const AnswerAspectToIdea = ({ aspects, question }: AnswerAspectToIdeaProps) => {
  const [answerByAspect, setAnswerByAspect] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [idea, setIdea] = useState(null);
  const keyword = useMemo(() => {
    return Object.keys(answerByAspect).reduce((prev, val) => {
      return `${prev} ${answerByAspect[val] ?? ""}\n`;
    }, "");
  }, [answerByAspect]);
  console.log("keyword", keyword);
  const onGenIdeas = async () => {
    setIsLoading(true);

    try {
      const apiUrl = getApiUrl(ENDPOINTS.KEYWORD_TO_IDEA);
      const res = await Requests.post(apiUrl, {
        question,
        keyword,
      });

      if (res.data?.result?.length) {
        const item = res.data?.result[0];
        setIdea({
          genByAI: true,
          _id: randomStringFromDatetime(),
          name: `${item.title} (${item.detail})`,
          description: item.example_answer,
          locationIdea: "",
        });
      }
    } catch (e) {}
    setIsLoading(false);
  };
  return (
    <Box>
      <Box>
        {aspects.map((aspect) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "4px",
              }}
            >
              <Box
                sx={{
                  width: "180px",
                  fontWeight: "bold",
                }}
                dangerouslySetInnerHTML={{ __html: aspect.name || "" }}
              />
              <Input
                sx={{
                  flex: 1,
                  border: "1px solid #e0e0e0",
                  borderRadius: "10px",
                  padding: "8px",
                  "&::after": {
                    display: "none",
                  },
                  "&::before": {
                    display: "none",
                  },
                }}
                placeholder="Enter my answer ..."
                value={answerByAspect[aspect._id] ?? ""}
                onChange={(event) => {
                  setAnswerByAspect((prev: any) => ({
                    ...prev,
                    [aspect._id]: event.target.value,
                  }));
                }}
              />
            </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          onClick={() => {
            onGenIdeas();
          }}
          variant="contained"
          color="primary"
          sx={{
            textTransform: "unset",
          }}
          disabled={isLoading || keyword.trim() === ""}
        >
          {isLoading && (
            <CircularProgress
              size={20}
              sx={{
                marginRight: "10px",
              }}
            />
          )}
          Gen ideas
        </Button>
      </Box>
      {idea && <ListIdeaAI ideas={[idea]} />}
    </Box>
  );
};
export default AnswerAspectToIdea;
