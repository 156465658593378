import WordSearch from "@/pages/LearnPage/components/WordSearch";
import { getWordSearch } from "@/store-redux";
import { Box, Switch, Typography } from "@mui/material";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

function AddVocabulary() {
  const dispatch = useDispatch();
  const [enableAdd, setEnableAdd] = useState(false);
  const [showWord, setShowWord] = useState(false);
  const enableAddRef = useRef(enableAdd);
  const getWordSearchFn = (word: string) => {
    dispatch(getWordSearch(word));
  };
  const debounceGetWordSearchFn = useCallback(_.debounce(getWordSearchFn, 1000), []);

  useEffect(() => {
    const handleMouseUp = () => {
      const selectedText = (window?.getSelection()?.toString() ?? "").trim();
      if (selectedText !== "" && enableAddRef.current) {
        getWordSearchFn("");
        debounceGetWordSearchFn(selectedText);
      }
    };
    const editorEl = document.querySelector(".free-learn-to-idea__container");
    editorEl?.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchend", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, []);

  return (
    <Box
      sx={{
        position: "fixed",
        right: "32px",
        bottom: "32px",
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Box
        sx={{
          display: showWord ? "block" : "none",
          marginBottom: "16px",
        }}
      >
        <WordSearch
          showWordSearch={() => {
            setShowWord(true);
          }}
          hideWordSearch={() => {
            setShowWord(false);
          }}
        />
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Typography
          component="span"
          sx={{
            fontSize: "14px",
            marginTop: "4px",
          }}
        >
          Add vocabulary
        </Typography>
        <Switch
          checked={enableAdd}
          onChange={() => {
            setEnableAdd(!enableAdd);
            enableAddRef.current = !enableAdd;
          }}
        />
      </Box>
    </Box>
  );
}

export default AddVocabulary;
