export const onPlayReadStr = (str: string, onNext?: () => void, lang = "en-US") => {
  try {
    if ("speechSynthesis" in window) {
      const synth = window.speechSynthesis;
      synth.cancel();
      const utterance = new SpeechSynthesisUtterance(str);
      utterance.lang = lang;
      utterance.rate = localStorage.getItem("speed_audio") ? Number(localStorage.getItem("speed_audio")) / 100 : 1;
      utterance.pitch = localStorage.getItem("pitch_audio") ? Number(localStorage.getItem("pitch_audio")) / 100 : 1;
      synth.speak(utterance);

      utterance.onend = function () {
        onNext && onNext?.();
      };
    }
  } catch (error) {
    console.log("onPlayReadStr", error);
  }
};
