import React, { useMemo, useState } from "react";
import { IUserAnswers, MatchingExerciseData } from "@/common";
import { Box } from "@mui/material";
import { green, red } from "@mui/material/colors";

interface Props {
  data: MatchingExerciseData;
  isSubmitted?: boolean;
  setUserAnswers: React.Dispatch<React.SetStateAction<IUserAnswers>>;
}

const MatchingExercise: React.FC<Props> = ({ data, isSubmitted, setUserAnswers }) => {
  const [selectedPairs, setSelectedPairs] = useState<Record<string, string>>({});
  const handleSelect = (vietnamese: string, english: string): void => {
    const newSelectedPairs = {
      ...selectedPairs,
      [vietnamese]: english,
    };
    setSelectedPairs(newSelectedPairs);
    setUserAnswers((prev) => ({
      ...prev,
      matching: newSelectedPairs as Record<string, string>, // Type assertion to fix type error
    }));
  };

  const isCorrectAnswer = (vietnamese: string, english: string) => {
    const pair = data.pairs.find((p) => p.vietnamese === vietnamese);
    return pair?.english === english;
  };

  const listOptionRandom = useMemo(() => {
    return data.pairs
      .map((pair) => pair.english)
      .sort(() => Math.random() - 0.5)
      .reverse();
  }, [data.pairs]);

  return (
    <Box className="exercise-section" sx={{}}>
      <h2>Matching Exercise</h2>
      <p>{data.instructions}</p>

      <Box
        className="matching-container"
        sx={{
          display: "flex",
          gap: "30px",
          "&>div": {
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          },
          "& .english-column": {
            "& .matching-item": {
              display: "flex",
            },
          },
        }}
      >
        <div className="vietnamese-column">
          {data.pairs.map((pair, index: number) => (
            <div key={pair.vietnamese} className="matching-item">
              {`${index + 1}) `}
              {pair.vietnamese}
            </div>
          ))}
        </div>

        <div className="english-column">
          {data.pairs.map((pair) => (
            <div key={pair.english} className="matching-item">
              <select
                onChange={(e) => handleSelect(pair.vietnamese, e.target.value)}
                value={selectedPairs[pair.vietnamese] || ""}
                style={{
                  backgroundColor: isSubmitted
                    ? isCorrectAnswer(pair.vietnamese, selectedPairs[pair.vietnamese])
                      ? green[500]
                      : red[500] // red background for incorrect
                    : "white",
                  color: "black",
                }}
                disabled={isSubmitted}
              >
                <option value="">Select translation</option>
                {listOptionRandom.map((p) => (
                  <option key={p} value={p}>
                    {p}
                  </option>
                ))}
              </select>

              {isSubmitted && !isCorrectAnswer(pair.vietnamese, selectedPairs[pair.vietnamese]) ? (
                <Box
                  sx={{
                    marginLeft: "8px",
                    color: green[500],
                  }}
                >
                  {pair.english}
                </Box>
              ) : null}
            </div>
          ))}
        </div>
      </Box>
    </Box>
  );
};

export default MatchingExercise;
