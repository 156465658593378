import PitchAudio from "@/components/PitchAudio";
import SpeedAudio from "@/components/SpeedAudio";
import { matchedLetterAnswer, onPlayReadStr, removedLetterAnswer } from "@/helpers";
import { useGetDictionary } from "@/services-react-query/queries";
import { IApplicationState } from "@/store-redux";
import { Box, colors, Switch, Typography } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

function TabShadowing() {
  const editCustomAnswerContent = useSelector((state: IApplicationState) => state.editCustomAnswer);
  const wordArray = removedLetterAnswer(editCustomAnswerContent.data);
  const matches = matchedLetterAnswer(editCustomAnswerContent.data);
  const [showIpa, setShowIpa] = useState(false);
  const [speakEnd, setSpeakEnd] = useState(false);
  const textSelectRef = useRef("");

  const { data: dataWordSearch, isFetched: isFetchedWordSearch } = useGetDictionary({
    query: {
      keyword: "",
    },
    options: {
      enabled: false,
    },
  });

  useEffect(() => {
    textSelectRef.current &&
      onPlayReadStr(textSelectRef.current, () => {
        setSpeakEnd((prev) => !prev);
      });
  }, [speakEnd]);

  useEffect(() => {
    const editorWrapperEl = document.getElementById("box_shadowing");
    const handleMouseUp = () => {
      const selectedText = (window?.getSelection()?.toString() ?? "").trim()?.replace(/\s+/g, " ");
      if (selectedText !== "") {
        textSelectRef.current = selectedText;
        if (textSelectRef.current) {
          setSpeakEnd((prev) => !prev);
        } else {
          onPlayReadStr(selectedText, () => {
            setSpeakEnd((prev) => !prev);
          });
        }
      } else {
        textSelectRef.current = "";
      }
    };
    editorWrapperEl?.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("touchend", handleMouseUp);
    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchend", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const clickWhenSelect = () => {
      const selection = window.getSelection();
      if ((!selection || selection.isCollapsed) && textSelectRef.current) {
        textSelectRef.current = "";
      }
    };
    document.addEventListener("click", clickWhenSelect);
    return () => document.removeEventListener("click", clickWhenSelect);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "12px",
        }}
      >
        <PitchAudio />
        <SpeedAudio />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            component="span"
            sx={{
              fontSize: "14px",
              marginTop: "4px",
            }}
          >
            Show IPA
          </Typography>
          <Switch checked={showIpa} onChange={() => setShowIpa(!showIpa)} />
        </Box>
      </Box>
      <Box
        sx={{
          maxWidth: "calc(100vw - 32px)",
          height: "calc(100vh - 250px)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            component={"ul"}
            sx={{
              listStyle: "none",
              display: "flex",
              flex: "row",
              justifyContent: "space-evenly",
              marginTop: "20px",
              border: "1px solid black",
              padding: "5px 0",
              borderRadius: "8px",
              "& li": {},
            }}
          >
            {matches?.map((item, idx) => (
              <Box component={"li"} key={idx}>
                {item.replace(/[()]/g, "")}
              </Box>
            ))}
          </Box>
          <div id="box_shadowing">
            <Box
              sx={{
                marginTop: "20px",
                overflow: "auto",
                maxHeight: "72vh",
                maxWidth: "calc(100vw - 32px)",
              }}
            >
              {wordArray
                .join(" ")
                .split(".")
                .map((sentence, index) => {
                  return (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        flexFlow: "row wrap",
                        marginBottom: "12px",
                      }}
                    >
                      {sentence.split(" ").map((singleWord, index) => {
                        if (index === wordArray.length || !singleWord.trim()) return null;
                        const dataFind =
                          isFetchedWordSearch &&
                          dataWordSearch?.items.find(
                            (item: any) => item.word === singleWord?.trim()?.replace(/[,.]/g, "")?.toLocaleLowerCase()
                          );
                        const ipa = dataFind?.phonetic?.us;
                        return (
                          <Fragment key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                marginBottom: "20px",
                              }}
                            >
                              {isFetchedWordSearch && showIpa ? (
                                <span
                                  style={{
                                    fontSize: 18,
                                    fontStyle: "italic",
                                    color: colors.grey[700],
                                    userSelect: "none",
                                    WebkitUserSelect: "none",
                                    MozUserSelect: "none",
                                    msUserSelect: "none",
                                  }}
                                >
                                  {ipa ?? "---"}
                                </span>
                              ) : null}
                              <Typography
                                sx={{
                                  fontWeight: "400",
                                  paddingInline: "3px",
                                  color: "black",
                                  cursor: "text",
                                  fontSize: "20px",
                                }}
                                component="span"
                              >
                                {singleWord + " "}
                              </Typography>
                            </Box>
                            {singleWord.indexOf(".") !== -1 ? <br /> : null}
                          </Fragment>
                        );
                      })}
                    </Box>
                  );
                })}
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default TabShadowing;
